import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import "../blog/blog.sass";
import WhiteCercle from "../../assets/images/big-cercle-white.svg";
import BlackCercle from "../../assets/images/black-circle.svg";
import LongCercle from "../../assets/images/long-cercle.svg";
import NoBlogImg from "../../assets/images/no-image.svg";
import LoadingLoder from "../../assets/images/loading-loder.gif";
import { Tab, Tabs } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ShareModal from '../shareModal';
import InfiniteScroll from 'react-infinite-scroll-component';

const BASE_URL = process.env.REACT_APP_API_BASE_URL
const getAllBlogUrl = `${BASE_URL}/api/v1/moxie/blog`
const getBlogTagUrl = `${BASE_URL}/api/v1/moxie/blog/tags`

const Blog = () => {

  const [shareModal, setShareModal] = useState<boolean>(false)
  const [blogData, setBlogData] = useState<any>([])
  const [blogTag, setBlogTag] = useState<any>([])
  const [filters, setFilters] = useState<any>([])
  const [selectedBlogSlugID, setSelectedBlogSlugID] = useState('')
  const params = useParams<{ _id: string }>()
  const shareModalToggle = () => setShareModal(!shareModal)
  const setSharedModalToggle = (slug: any) => setSelectedBlogSlugID(slug || '')
  const [skipData, setSkipData] = useState(0)
  const [endData, setEndData] = useState(false)
  const [totalBlogLength, setTotalBlogLength] = useState(0)
  const [startingCounter, setStartingCounter] = useState(0)
  const [selectedTab, setSelectedTab] = useState("All")

  let apiRequestDataSetLimit = 5
  const nav = useNavigate();

  // blogs get api call
  function getAllBlogData(tabName:any, skipDataValue?: number){
    var queryStringParams = tabName == "All" ? `/?limit=${apiRequestDataSetLimit}&skip=${skipDataValue || 0}` : `/?limit=${apiRequestDataSetLimit}&skip=${skipDataValue || 0}&tag=${tabName}`
      axios
      .get(getAllBlogUrl + queryStringParams)
      .then((response) => {
        const myBlog = response.data.values;
        const totalDataSet = response.data.total;
        setBlogData((prev: any) => [...prev, ...myBlog])
        setTotalBlogLength(totalDataSet);
        })
        .catch(function (error) {
          alert(error);
        });
  };

  const getBlogNameData = async () => {
    axios
      .get(getBlogTagUrl)
      .then((response) => {
        const myBlog = response.data.values;
        var defaultDataArray = [{name: "All"}]
        const combined2 = [...defaultDataArray, ...myBlog];
        setBlogTag(combined2)
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const fetchdata = () => {
    setTimeout(() => {
      getAllBlogData(selectedTab, startingCounter + apiRequestDataSetLimit)
      setStartingCounter(startingCounter + apiRequestDataSetLimit)
    }, 1000);
  }

  useEffect(() => {
    if (params) {
      setBlogData([])
      getBlogNameData()
      getAllBlogData(selectedTab, startingCounter)
    }
  }, [params])
  
  return (
    <>
      <div>
        <div className="">
          <Container className="position-relative">
            <section className="blog-hero-section">
              <img
                src={WhiteCercle}
                alt=""
                className="img-fluid big-white-cercle"
              />
              <img
                src={BlackCercle}
                alt=""
                className="img-fluid black-cercle"
              />
              <img
                src={LongCercle}
                alt=""
                className="img-fluid long-cercle"
              />
              <img
                src={BlackCercle}
                alt=""
                className="img-fluid small-black-cercle"
              />
              <img
                src={BlackCercle}
                alt=""
                className="img-fluid small-white-cercle second medium black"
              />
              <img
                src={LongCercle}
                alt=""
                className="img-fluid long-cercle second"
              />
              <div className="border-font-content text-center">
                <span>BLOG</span>
              </div>
            </section>
          </Container>
          <section className="blog-tab-section">
            <Container>
              <Tabs
                defaultActiveKey="All"
                id="justify-tab-example"
                justify
                onSelect={(e) =>{
                  setBlogData([])
                  setStartingCounter(0);
                  setSelectedTab(e || "")
                  getAllBlogData(e, 0)
                }}
              >
                {blogTag.map((item: any, i: any) => (
                <Tab eventKey={item.name} title={item.name} key={i}>
                  

                </Tab>
                ))}

            <Tab.Pane eventKey={selectedTab}>
            <div className="" id="scrollableDiv">
                    {blogData.map((item: any, i: any) => (
                      <div className="blog-card" key={i} >
                        <Row>
                          <Col lg={6} md={12} sm={12}>
                            <div className="blog-img" onClick={() => { nav(`/blog/${item.slug}`) }}>
                              {item.bannerUrl ?
                                <img src={item.bannerUrl} className="img-fluid" alt="blog-img" /> :
                                <img src={NoBlogImg} className="img-fluid" alt="blog-img" />
                              }
                            </div>
                          </Col>
                          <Col lg={6} md={12} sm={12}>
                            <div className="second-card-body">
                              <div className="second-card" onClick={() => { nav(`/blog/${item.slug}`) }}>
                                <h2>{item?.heading}</h2>
                                <div className="second-card-icon">
                                  <i className="ri-arrow-right-s-line"></i>
                                </div>
                              </div>
                              <div className="second-card-footer">
                                {item && item?.tags?.length > 0 && <div className="footer-btn">
                                  {item?.tags.map((items: any, i: any) => (
                                    <button className="border-button" key={i}>{items}</button>
                                  ))}
                                </div>}
                                <div className="footer-icon">
                                  {/* <i className="ri-heart-line"></i> */}
                                  {/* <i className="ri-message-2-line"></i> */}
                                  <i className="ri-share-line" onClick={() => {
                                    setSharedModalToggle(item?.slug)
                                    shareModalToggle()
                                  }}></i>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <InfiniteScroll
                    dataLength={blogData.length}
                    next={fetchdata}
                    hasMore={Number(blogData.length) >= Number(totalBlogLength) ? false : true}
                    loader={
                    blogData.length < totalBlogLength ? 
                      <div className="text-center">
                        <img src={LoadingLoder} className="img-fluid text-center" alt="loader" />
                      </div> : 
                      blogData.length == 0 ? 
                      <div className="text-center">
                        <h4>No Data Available</h4>
                      </div> 
                    : ""
                  }
                    endMessage={
                      blogData.length == 0 ? 
                      <div className="text-center">
                        <h4>No Data Available</h4>
                      </div> :
                      <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                  >
                    </InfiniteScroll>
                  </div>
            </Tab.Pane>

                {/* <Tab eventKey="metaverse" title="Metaverse" disabled>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt autem tempora culpa blanditiis nulla deleniti dolore quos, dolorem nisi praesentium.
                </Tab>
                <Tab eventKey="NFTs" title="NFTs" disabled>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima corrupti distinctio error neque aliquam velit maiores saepe commodi excepturi odio?
                </Tab>
                <Tab eventKey="web3" title="Web3" disabled>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cumque quisquam, maxime minus maiores fugiat necessitatibus expedita? Nemo suscipit iusto perspiciatis!
                </Tab> */}
              </Tabs>
            </Container>
          </section>
          <ShareModal isOpen={shareModal} toggle={shareModalToggle} slug={selectedBlogSlugID} />
        </div>
      </div>
    </>
  );
};

export default Blog;
