import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
// import "../why-moxie/why-moxie.sass";
import { useLocation, useNavigate } from 'react-router-dom';
import SmartContract from "../../../assets/images/smart-contract.svg";
import DefiProduct from "../../../assets/images/defi-product.svg";
import LongCercle from "../../../assets/images/long-cercle.svg";
import WhiteCercle from "../../../assets/images/big-cercle-white.svg";
import SmallWhiteCercle from "../../../assets/images/white-circle.svg";
export const MoxieBuild = () => {

    const location = useLocation();
    const nav = useNavigate();

    return (
        <>
                  <section className="studios-section bg-black position-relative" id={'moxiebuild'}>
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg={6} md={9} className="position-relative">
                  <div className="text-center mb-lg-5 mb-4">
                    <h2 className="color-white">
                      Moxie<strong className="color-primary">Build</strong>
                    </h2>
                    <h6 className="my-lg-4 my-md-3 my-4 color-white">
                      TAILORMADE SOLUTIONS TO MATCH UNIQUE REQUIREMENTS
                    </h6>
                    <p className="color-white">
                      Our team of experienced developers is here to bring your
                      web3 project to life. We’ll help you #buidl the next big
                      product on your mind - we love working on innovative new
                      products which will power the future of DeFi.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-around">
                <Col lg={4} md={6} className="align-self-center">
                  <div className="studios-card text-center">
                    <div className="card-image">
                      <img src={SmartContract} alt="" className="img-fluid" />
                    </div>
                    <div className="card-content">
                      <h5 className="my-lg-5 my-4 color-white">Custom <br /> Smart Contracts</h5>
                      <Button className="default-button secondary" onClick={()=>{nav("/contact")}}>Make an inquiry</Button>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={6} className="align-self-center">
                  <div className="studios-card text-center">
                    <div className="card-image">
                      <img src={DefiProduct} alt="" className="img-fluid" />
                    </div>
                    <div className="card-content">
                      <h5 className="my-lg-5 my-4 color-white">Custom <br /> DeFi products</h5>
                      <Button className="default-button secondary" onClick={()=>{nav("/contact")}}>Make an inquiry</Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <img src={LongCercle} alt="" className="img-fluid build-long-shape" />
            <img src={WhiteCercle} alt="" className="img-fluid build-big-shape" />
            <img src={SmallWhiteCercle} alt="" className="img-fluid build-small-shape" />
          </section>
        </>
    )
}
