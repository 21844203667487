import React, { useState } from 'react';
import './contact.sass';
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Avalance from "../../assets/images/avalance-logo.svg";
import Polygon from "../../assets/images/Polygon-logo.svg";
import Ethereum from "../../assets/images/ethereum.svg";
import Binance from "../../assets/images/Binance-Logo.svg";
import Harmony from "../../assets/images/harmony.svg";
import { WhyMoxie } from '../../shared/components/why-moxie/why-moxie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';

const BASE_URL = process.env.REACT_APP_API_BASE_URL
const addUserApiUrl = `${BASE_URL}/api/v1/moxie/contact`

const Contact = () => {

  const [userRegister, setUserRegister] = useState({
    name: "", email: "", telegramId: "", industry: "", blockChain: "", products: "", requirementUrgency: "",
    otherNotes: "", file: "", tentativeLaunchDate: ""
  });

  const handleChange = (event: any) => {
    setUserRegister({
      ...userRegister,
      [event.target.name]: event.target.value
    });
  }

  const handleSubmit = (event: any) => {

    event.preventDefault();

    var checkErrorFlag = false
    if (userRegister.name.length <= 5) {
      checkErrorFlag = true
      toast.error("Name must greter then 5 charachter");
    }
    else if (userRegister.telegramId.length <= 5) {
      checkErrorFlag = true
      toast.error("Please select unique telegram Id");
    }
    else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(userRegister.email)) {
      // toast.success("Please enter valid email ");
      // console.log("hiiiiii")
      checkErrorFlag = true
      toast.error("Please enter valid email ");
    } 
    // else {
    //   toast.error("Please enter valid email ");
    // }

    var formData = new FormData();
    formData.append("name", userRegister.name);
    formData.append("email", userRegister.email);
    formData.append("telegramId", userRegister.telegramId);
    formData.append("industry", userRegister.industry);
    formData.append("blockChain", userRegister.blockChain);
    formData.append("products", userRegister.products);
    formData.append("requirementUrgency", userRegister.requirementUrgency);
    formData.append("otherNotes", userRegister.otherNotes);
    formData.append("file", userRegister.file);
    formData.append("tentativeLaunchDate", userRegister.tentativeLaunchDate);

    if(!checkErrorFlag){
      axios
        .post(addUserApiUrl, userRegister, {headers: { 'content-type': 'multipart/form-data' }} )
        .then((response) => {
          if(response.status === 200) {
            localStorage.setItem("newUser", JSON.stringify(response.data));
            toast.success("Contact saved successfully");
          }
        })
        .catch(function (error) {
          toast.error("Invalid Form details", error);
        });
    }
  };

  return (
    <>
    <ToastContainer />
      <section className='contact-section'>
        <div className="padding-section">
        <Container>
          <Row>
            <Col lg={6} md={12} xs={12} className="position-relative">
              <div className="text-part">
                <div className="border-font-content">
                  <span>Contact</span>
                </div>
                <h2 className=''>Welcome to Moxie. Your one-stop technology provider for web3.</h2>
                <p className='lg-mb-4 mb-2'>We specialise in making top notch products for the web3 space.
                  NFT marketplace, IDO launchpad, diamond hands products,
                  unique staking solutions - you name it, we have worked on it.
                </p>
                <p>Get in touch with us today to discuss your next big product!</p>
                <h5 className="mb-5 fw-bold">Compatible with all major Chains</h5>
                <div className="chain-company">
                  <div className="chain-logo">
                    <img src={Ethereum} alt="" />
                  </div>
                  <div className="chain-logo">
                    <img src={Binance} alt="" />
                  </div>           
                  <div className="chain-logo">
                    <img src={Polygon} alt="" />
                  </div>
                  <div className="chain-logo">
                    <img src={Avalance} alt="" />
                  </div>
                  <div className="chain-logo">
                    <img src={Harmony} alt="" />
                  </div>
                </div>
                <div className="border-font-content-footer">
                  <span>DESIGN. BUIDL. DEPLOY.</span>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12} xs={12} className="position-relative">
              <div className='contact-form'>
                <form action="" onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={3} md={3} xs={3}>
                      <label>Name <span className='input-valid-star'>*</span></label>
                    </Col>
                    <Col lg={9} md={9} xs={9}>
                      <input type="text" name='name' className='contact-fields' onChange={handleChange}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={3} xs={3}>
                      <label>Email <span className='input-valid-star'>*</span></label>
                    </Col>
                    <Col lg={9} md={9} xs={9}>
                      <input type="email" name='email' className='contact-fields' onChange={handleChange}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={3} xs={3}>
                      <label>Telegram ID <span className='input-valid-star'>*</span></label>
                    </Col>
                    <Col lg={9} md={9} xs={9}>
                      <input type="text" name='telegramId' className='contact-fields' onChange={handleChange}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} xs={6}>
                      <label htmlFor="" className='select-label'>Industry</label>
                      <Form.Select aria-label="Default select contact-select contact-fields" name='industry' onChange={handleChange}>
                        <option aria-readonly>Please select</option>
                        <option value="Gaming">Gaming</option>
                        <option value="DeFi">DeFi</option>
                        <option value="Metaverse">Metaverse</option>
                        <option value="DAO">DAO</option>
                        <option value="Ecommerce">Ecommerce</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                    <Col lg={6} md={6} xs={6}>
                      <label htmlFor="" className='select-label'>Blockchain</label>
                      <Form.Select aria-label="Default select contact-select contact-fields" name='blockChain' onChange={handleChange}>
                        <option>Please select</option>
                        <option value="Ethereum">Ethereum</option>
                        <option value="Polygon">Polygon</option>
                        <option value="BNB Chain">BNB Chain</option>
                        <option value="Avalanche">Avalanche</option>
                        <option value="Harmony">Harmony</option>
                        <option value="Hedera">Hedera</option>
                        <option value="Undecided">Undecided</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={3} xs={3}>
                      <label className='col-lg-3' htmlFor="">Products</label>
                    </Col>
                    <Col lg={9} md={9} xs={9}>
                        <Form.Select aria-label="Default select contact-select contact-fields" name='products' onChange={handleChange}>
                          <option>Please select</option>
                          <option value="NFT Marketplace">NFT Marketplace</option>
                          <option value="Launchpad">Launchpad</option>
                          <option value="Staking">Staking</option>
                          <option value="NFT Staking">NFT Staking</option>
                          <option value="LP">LP</option>
                          <option value="NFT Minting">NFT Minting</option>
                          <option value="Web3 Game">Web3 Game</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                      </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} xs={6}>
                      <label htmlFor="" className='select-label'>Requirement <br /> Urgency</label>
                      <Form.Select aria-label="Default select contact-select contact-fields" name='requirementUrgency' onChange={handleChange}>
                        <option>Please select</option>
                        <option value="immediate">immediate</option>
                        <option value="1-3 months">1-3 months</option>
                        <option value="3-6 months">3-6 months</option>
                      </Form.Select>
                    </Col>
                    <Col lg={6} md={6} xs={6}>
                      <label htmlFor="" className='select-label'>Tentative <br /> Launch Date</label>
                      <input type="date" className='react-date-picker contact-fields' name='tentativeLaunchDate' onChange={handleChange} />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={3} xs={3}>
                      <label>Other Notes</label>
                    </Col>
                    <Col lg={9} md={9} xs={9}>
                      <input type="text" onChange={handleChange} className="contact-fields" name='otherNotes' />
                    </Col>
                  </Row>
                  <Row className='justify-content-center'>
                    <Col lg={10}>
                      <div className="file-upload">
                        <label className=''>Upload a file</label>
                        <input type="file" className='contact-fields' onChange={handleChange} name='file'  />
                      </div>
                    </Col>
                  </Row>
                  <div className="submit-btn">
                    <Button type='submit'> Submit</Button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
        </div>
      </section>
      <div className="contact-page">
        <WhyMoxie />
      </div>
    </>
  )
}

export default Contact