import React from "react";
import Container from "react-bootstrap/esm/Container";
import { useLocation } from "react-router-dom";
import "../about/about.sass";


const About = () => {

  const location = useLocation();

  return (
    <>
      <div>
        <div className="">
          <section className="about-content">
            <Container className="position-relative">
              <div className="padding-section">
                <p>
                  Introducing Moxie, a cutting-edge platform that is revolutionizing the world of Web3 innovation. At Moxie, we believe in building super cool products that not only look amazing but also deliver outstanding performance. Our goal is to provide secured web3 solutions that cater to the unique needs of each of our clients.
                </p>
                <p className="my-3 my-md-4 my-lg-4">With years of experience in the blockchain industry, our team has a deep understanding of the technological and practical challenges facing the DeFi, NFT, and overall Web3 space.</p>
                <p>
                  Our mission is to bring Web3 to the masses, making it accessible and easy for everyone to participate and benefit from the decentralized financial revolution. Whether you're an individual, a startup, or an enterprise, we are committed to delivering the highest quality and innovative products to help you succeed in the fast-paced, ever-changing world of Web3.                </p>
                <h6 className="my-3 my-md-4 my-lg-4">Staking </h6>
                <ul className="about-ul my-3 my-md-4 my-lg-4">
                  <li>
                    <h6 className="mb-0">NFTized Staking - Staking with NFT Rewards</h6>
                  </li>
                </ul>
                <p>One of the highlights of Moxie is our state-of-the-art staking product, NFTized Staking. This is the first-of-its-kind staking product that comes with NFT rewards. The higher the amount one stakes, the rarer the NFT reward they will receive. These NFT rewards are custom-designed for each project, making them truly unique. On top of that, these NFTs can be traded on the open market and come with on-demand utility attached.</p>
                <ul className="about-ul my-3 my-md-4 my-lg-4">
                  <li>
                    <h6 className="mb-0">Cohort Staking - Staking with Multi-Token Rewards</h6>
                  </li>
                </ul>
                <p>In addition to NFTized Staking, we also offer cohort staking. This innovative solution allows users to stake one token and receive multiple tokens as rewards. This opens up extensive cross-marketing opportunities and access to GS Alliance Guild benefits. Our cohort staking solution provides high APY and low risk, making it a great investment option.</p>
                <ul className="about-ul my-3 my-md-4 my-lg-4">
                  <li>
                    <h6 className="mb-0">Regular Staking - Flexible Staking Solutions</h6>
                  </li>
                </ul>
                <p>For those who prefer a more traditional approach, we offer regular staking. This solution allows one to stake their project's tokens and earn rewards. The time period is flexible, allowing them to lock tokens for 15, 30, or 60 days. The rewards can be claimed at any time, giving them complete control over their investment.</p>

                <h6 className="my-3 my-md-4 my-lg-4">NFT Marketplace - A Multi-Chain NFT Trading Platform</h6>
                <p>Moxie Studios also offers a state-of-the-art NFT marketplace that supports multiple chains, and multiple standards of NFTs including ERC 1155 and ERC 721. This platform provides increased on-chain liquidity, multi-chain support, and flexible commission rates on NFT trades, and ensures a simple, user-friendly experience for everyone.</p>

                <h6 className="my-3 my-md-4 my-lg-4">IDO Launchpad - A White-Label Solution</h6>
                <p>We also provide a white-label IDO launchpad, built by the team that built Genpad. This platform can be customized and white-labeled to match your brand's needs. It includes customized website designs, full-stack development, audited smart contracts, and tech support when needed.</p>

                <h6 className="my-3 my-md-4 my-lg-4">MOXIE BUIDL - Custom-Building Solutions for You</h6>
                <p>At Moxie, we understand that every client is unique, and that's why we offer MOXIE BUIDL, a service that helps build custom solutions tailored to your needs. Our team of skilled UI/UX designers, blockchain developers, and full-stack developers are at your service, ready to help you build your vision. Whether it's a custom yield farming product, a decentralized exchange, or a cross-chain bridge, we have got you covered.</p>
                <p className="my-3 my-md-4 my-lg-4">We believe that everyone should have the opportunity to participate in the decentralized financial revolution and reap the benefits it brings. With MOXIE, you can be confident that you're working with a team of experts who are dedicated to delivering world-class solutions that will help you achieve your goals. Let us help you #BUIDL your next big thing!</p>
                <p>Follow us on <span className="about-link" onClick={() => { window.open('https://twitter.com/Moxiestudios_?t=OXW0QVkHPxVBeoh1ahIQNg&s=09') }}>Twitter</span> and <span className="about-link" onClick={() => { window.open('https://www.linkedin.com/showcase/getmoxiexyz/') }}>LinkedIn</span>  where we share the latest updates and expert insights.</p>
              </div>
            </Container>
          </section>
        </div>
      </div>
    </>
  );
};

export default About;
