import React from "react";
import "./App.sass";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./shared/components/header/header";
import Home from "./pages/home/home";
import 'remixicon/fonts/remixicon.css'
import Blog from "./pages/blog/blog";
import Footer from "./shared/components/footer/footer";
import BlackHeader from "./shared/components/black-header/black-header";
import SecondFooter from "./shared/components/second-footer/second-footer";
import ScrollToTop from "./shared/components/scrolltotop/scrolltotop";
import BlogDetails from "./pages/blog-details/blog-details";
import Contact from "./pages/contact/contact";
import About from "./pages/about/about";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<><Header/><Home /><Footer/></>}></Route>
          <Route path="/about" element={<><BlackHeader/><About /><SecondFooter/></>}></Route>
          <Route path="/blog" element={<><BlackHeader/><Blog /><SecondFooter/></>}></Route>
          <Route path="/blog/:slug" element={<><BlackHeader/><BlogDetails /><SecondFooter/></>}></Route>
          <Route path="/contact" element={<><BlackHeader/><Contact /><SecondFooter/></>}></Route>
        </Routes>
        
      </Router>
    </>
  );
}

export default App;
