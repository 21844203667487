import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import "../blog-details/blog-details.sass";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import WhiteCercle from "../../assets/images/big-cercle-white.svg";
import BlackCercle from "../../assets/images/black-circle.svg";
import LongCercle from "../../assets/images/long-cercle.svg";

import ShareModal from '../shareModal';

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const BlogDetails = () => {
  const params = useParams<{ slug: string }>()
  const [selectedBlogSlugID, setSelectedBlogSlugID] = useState('')
  const [academy, setAcademy] = useState<any>({})
  const [shareModal, setShareModal] = useState<boolean>(false)
  const shareModalToggle = () => setShareModal(!shareModal)
  const setSharedModalToggle = (slug: any) => setSelectedBlogSlugID(slug || '')

  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (params.slug) {
      axios.get(`${BASE_URL}/api/v1/moxie/blog/${params.slug}`).then(res => {
        setAcademy(res.data)
        console.log("hgdhad,", res.data)
      })
    }
  }, [params.slug])

  const { heading = "", image = "", link = "", content = "", description = "", type = "" } = academy

  return (
    <>
      <div>
        <div className="">
        <Container className="position-relative">
            <section className="blog-hero-section">
              <img
                src={WhiteCercle}
                alt=""
                className="img-fluid big-white-cercle"
              />
              <img
                src={BlackCercle}
                alt=""
                className="img-fluid black-cercle"
              />
              <img
                src={LongCercle}
                alt=""
                className="img-fluid long-cercle"
              />
              <img
                src={BlackCercle}
                alt=""
                className="img-fluid small-black-cercle"
              />
              <img
                src={BlackCercle}
                alt=""
                className="img-fluid small-white-cercle second medium black"
              />
              <img
                src={LongCercle}
                alt=""
                className="img-fluid long-cercle second"
              />
              <div className="border-font-content text-center">
                <span>BLOG</span>
              </div>
            </section>
          </Container>
          <section className="blog-content">
            <Container className="position-relative">
              <div className="defi-tab">
                <a className="back-btn" onClick={() => nav(`/blog`)}>
                  <i className="ri-arrow-left-s-line"></i>
                </a>
                <div>
                  <div className="defi-title">
                    <h2>{academy.heading}</h2>
                    <div className="defi-icon">
                      {/* <i className="ri-heart-line"></i> */}
                      {/* <i className="ri-message-2-line"></i> */}
                      <i className="ri-share-line" onClick={() => {
                        setSharedModalToggle(academy?.slug)
                        shareModalToggle()
                      }}></i>
                    </div>
                  </div>
                  <div className="defi-btn">
                    {academy && academy.tags && academy.tags.map((items: any, i: any) => (
                      <button className="border-button" key={i}>{items}</button>
                    ))}
                  </div>
                </div>
                {(content && typeof content === "object") ? <Editor
                  readOnly
                  toolbarHidden
                  editorState={EditorState.createWithContent(convertFromRaw(content))}
                  editorClassName="editor-editorClassName"
                /> : <p>{content || description}</p>}
                {/* <div className="comment">
                      <p>Comments</p>
                      <i className="ri-message-2-line"></i>
                      <input type="text" placeholder="Type your comment here..." />
                    </div>
                    <div className="all-comments">
                      <div className="comments-pic"><i className="ri-user-fill"></i></div>
                      <div className="comments-text">
                        <p>Lorem Ipsum</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam phasellus vestibulum lorem sed risus ultricies tristique. Et magnis dis parturient montes nascetur ridiculus. </p>
                      </div>
                    </div> */}
              </div>
            </Container>
          </section>
          <ShareModal isOpen={shareModal} toggle={shareModalToggle} slug={selectedBlogSlugID} />
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
