import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import "../home/home.sass";
import WhiteCercle from "../../assets/images/big-cercle-white.svg";
import BlackCercle from "../../assets/images/black-circle.svg";
import LongCercle from "../../assets/images/long-cercle.svg";
import SmallWhiteCercle from "../../assets/images/white-circle.svg";
import Button from "react-bootstrap/esm/Button";
import Logo from "../../assets/images/black-logo.svg";
import Marketplace from "../../assets/images/marketplace.svg";
import Launchpad from "../../assets/images/launchpad.svg";
import Staking from "../../assets/images/staking.svg";

import Avalance from "../../assets/images/avalance-logo.svg";
import Polygon from "../../assets/images/Polygon-logo.svg";
import Ethereum from "../../assets/images/ethereum.svg";
import Binance from "../../assets/images/Binance-Logo.svg";
import Harmony from "../../assets/images/harmony.svg";
import { WhyMoxie } from "../../shared/components/why-moxie/why-moxie";
import { useNavigate } from "react-router-dom";
import { MoxieBuild } from "../../shared/components/moxie-build/moxie-build";



const Home = () => {

  const nav = useNavigate();

  return (
    <>
      <div className="home-bg">
        <div className="">
          <Container fluid>
            <section className="hero-section position-relative">
              <Container>
                <Row>
                  <Col>
                    <img
                      src={WhiteCercle}
                      alt=""
                      className="img-fluid big-white-cercle"
                    />
                    <img
                      src={BlackCercle}
                      alt=""
                      className="img-fluid black-cercle"
                    />
                    <img
                      src={LongCercle}
                      alt=""
                      className="img-fluid long-cercle"
                    />
                    <img
                      src={SmallWhiteCercle}
                      alt=""
                      className="img-fluid small-white-cercle"
                    />
                    <img
                      src={BlackCercle}
                      alt=""
                      className="img-fluid small-black-cercle"
                    />
                    <img
                      src={SmallWhiteCercle}
                      alt=""
                      className="img-fluid small-white-cercle second"
                    />
                    <img
                      src={SmallWhiteCercle}
                      alt=""
                      className="img-fluid small-white-cercle second medium"
                    />
                    <img
                      src={SmallWhiteCercle}
                      alt=""
                      className="img-fluid small-white-cercle second medium"
                    />
                    <img
                      src={BlackCercle}
                      alt=""
                      className="img-fluid small-white-cercle second medium black"
                    />
                    <img
                      src={SmallWhiteCercle}
                      alt=""
                      className="img-fluid small-white-cercle second medium white"
                    />
                    <img
                      src={LongCercle}
                      alt=""
                      className="img-fluid long-cercle second"
                    />
                    <h1>Your one-stop technology provider for web3.</h1>
                    <Button className="find-btn mt-lg-4 mt-4" onClick={()=>{nav("/contact")}}>
                      Find out how Moxie can help you
                    </Button>
                  </Col>
                </Row>
              </Container>
            </section>
          </Container>

          <section className="be-ready-section position-relative">
            <Container>
              <div className="content">
                <h2>
                  Be Future Ready. <br /> Be Web3 Ready.
                </h2>
                <div className="arrow-line">
                  <div className="down-icon">
                    <i className="ri-arrow-down-s-line"></i>
                  </div>
                  <div className="steat-line"></div>
                </div>
                <p className="">
                  We specialise in making top notch products for the web3 space.
                  NFT marketplace, IDO launchpad, diamond hands products, unique
                  staking solutions - you name it, we have worked on it. Get in
                  touch with us today to discuss your next big product!
                </p>
              </div>
            </Container>
          </section>

          <section className="works-section position-relative">
            <Container fluid>
              <Row>
                <Col lg={7} md={7} xs={12} className="position-relative">
                  <img
                    src={BlackCercle}
                    alt=""
                    className="img-fluid black-cercle"
                  />
                  <img
                    src={WhiteCercle}
                    alt=""
                    className="img-fluid big-white-cercle"
                  />
                  <img
                    src={LongCercle}
                    alt=""
                    className="img-fluid long-cercle"
                  />
                  <div className="border-font-content">
                    <span>DESIGN</span>
                    <span>BUIDL</span>
                    <span>DEPLOY</span>
                  </div>
                </Col>
                <Col lg={5} md={5} xs={12} className="align-self-center position-relative">
                  <img
                    src={SmallWhiteCercle}
                    alt=""
                    className="img-fluid work-white-cercle"
                  />
                  <h3>
                    <img src={Logo} alt="" className="inline-logo" /> works for you, <br /> so that you
                    don’t have to.
                  </h3>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="major-chain-section position-relative">
            <Container fluid>
              <h5 className="text-center mb-5">Compatible with all major Chains</h5>
              <div className="on-scroll">
                <div className="chain-company">
                  <div className="chain-logo">
                    <img src={Ethereum} alt="" />
                  </div>
                  <div className="chain-logo">
                    <img src={Avalance} alt="" />
                  </div>
                  <div className="chain-logo">
                    <img src={Polygon} alt="" />
                  </div>
                  
                  <div className="chain-logo">
                    <img src={Binance} alt="" />
                  </div>
                  <div className="chain-logo">
                    <img src={Harmony} alt="" />
                  </div>
                </div>
              </div>
            </Container>
          </section>

          <section className="studios-section position-relative" id={'moxiestudios'}>
            <Container fluid>
              <Row className="justify-content-center position-relative">
                <Col lg={6} md={9} className="position-relative">
                  <div className="text-center mb-lg-5 mb-4">
                    <h2>
                      Moxie<strong className="color-primary">Studios</strong>
                    </h2>
                    <h6 className="my-lg-4 my-md-3 my-4 color-primary">
                      READY MADE TO DEPLOY. TAILOR MADE FOR YOUR NEEDS.
                    </h6>
                    <p>
                      We provide solutions to the web3 industry - from NFTs to
                      anything DeFi, we have a wide range of products to meet your
                      needs. Let us help you start your crypto business without
                      worrying about an amazing tech team behind your product!
                    </p>
                  </div>
                </Col>
                <img src={BlackCercle} alt="" className="img-fluid work-black-cercle" />
              </Row>
              <Row>
                <Col lg={4} md={6} xs={12}>
                  <div className="studios-card text-center">
                    <div className="card-image">
                      <img src={Staking} alt="" className="img-fluid" />
                    </div>
                    <div className="card-content">
                      <h5 className="mt-2 mb-0">NFT Marketplace</h5>
                      <p className="my-lg-5 my-md-4 my-4">
                        A state-of-the art NFT marketplace, with multiple chains
                        already integrated - and with many more in the pipeline.
                        Multiple user-friendly features which will make you stand
                        out from the rest, and with the option to pick and choose
                        which ones suit your needs.
                      </p>
                      <Button className="default-button" onClick={()=>{nav("/contact")}}>Make an inquiry</Button>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={6} xs={12}>
                  <div className="studios-card text-center">
                    <div className="card-image">
                      <img src={Launchpad} alt="" className="img-fluid" />
                    </div>
                    <div className="card-content">
                      <h5 className="mt-2">IDO launchpad</h5>
                      <p className="my-lg-5 my-md-4 my-4">
                        An IDO launchpad which integrates NFTs into the system,
                        giving new projects a cult-like following - a product like
                        none other existing now. All our contracts are audited, so
                        rest assured that security is of utmost priority when it
                        comes to us.
                      </p>
                      <Button className="default-button" onClick={()=>{nav("/contact")}}>Make an inquiry</Button>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={6} xs={12}>
                  <div className="studios-card studios-card-bg text-center">
                    <div className="card-image">
                      <img src={Marketplace} alt="" className="img-fluid" />
                    </div>
                    <div className="card-content">
                      <h5 className="mt-2">Staking</h5>
                      <p className="my-lg-5 my-md-4 my-4">
                        Many unique staking products which can be customized to
                        your exact requirements - and created by a team which has
                        quite a bit of experience with DeFi products. Our unique
                        reward mechanisms are sure to be a hit in your community!
                      </p>
                      <Button className="default-button" onClick={()=>{nav("/contact")}}>Make an inquiry</Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <MoxieBuild/>

          <WhyMoxie/>
        </div>
      </div>
    </>
  );
};

export default Home;
