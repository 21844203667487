import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import EsyImplinemtetion from "../../../assets/images/ui-ux-icon.svg";
import UiuxIcon from "../../../assets/images/esy-implinemtetion.svg";
import TimeIcon from "../../../assets/images/time-icon.svg";
import Logo from "../../../assets/images/moxie-black-logo.svg";
import "../why-moxie/why-moxie.sass";
import { useLocation } from 'react-router-dom';

export const WhyMoxie = () => {

    const location = useLocation();

    return (
        <>
            <section className="why-moxie-section">
                <Container fluid>
                    <div className="section-heading">
                        <div className="why-moxie-heading">
                            {
                                location.pathname === '/' ? <span className='border-font-content'>WHY</span> :
                                location.pathname === '/contact' ? <span>WHY</span> : ""
                            }
                            <img src={Logo} alt="" className="img-fluid" />
                        </div>
                    </div>
                    <Row>
                        <Col lg={4}>
                            <div className="why-moxie-card position-relative">
                                <h2 className="mb-lg-4 mb-md-4 mb-3">Simple UI/UX</h2>
                                <p className='why-moxie-card-p'>We believe in keeping things simple and making websites accessible for everyone. An easy-to-navigate interface will eliminate friction and ensure that users keep coming back!</p>
                                <img src={UiuxIcon} alt="" className="img-fluid easy-implementations" />
                            </div>
                            <div className="d-flex text-center cards">
                                <div className="why-moxie-card small mx-2">
                                    <img src={TimeIcon} alt="" />
                                    <h2>Save Time</h2>
                                </div>
                                <div className="why-moxie-card small why-moxie-card-bg mx-2">
                                    <h2 className="mb-lg-4 mb-md-0 mb-2">Super Fast</h2>
                                    <span className="font-big">50x</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="why-moxie-card position-relative">
                                <h2 className="mb-3 mb-lg-4 mb-md-4">Service and Support</h2>
                                <p className='why-moxie-card-p mb-3'>We provide ongoing support to ensure your website runs smoothly without downtime, so that you don't have to worry about it and focus on your ideas instead.</p>
                                <p className='why-moxie-card-p'>Join us today to start working on your blockchain ideas!</p>
                                <img src={EsyImplinemtetion} alt="" className="img-fluid uiux-icon" />
                            </div>
                            <div className="why-moxie-card why-moxie-card-bg">
                                <h2 className="mb-3 mb-lg-4 mb-md-4">Reliable and Secure</h2>
                                <p className="mb-3">All our smart contracts and code are audited by some of the top audit agencies worldwide. We comply with all the accepted coding standards, and our previous audit records have been flawless and speak for themselves.</p>
                                <p>All our developers have hands-on experience with multiple web3 products and keep all the security best practices in mind while #buidling for you!</p>
                                {/* <p>Nec nam aliquam sem et tortor consequat. Fusce id velit ut tortor pretium viverra suspendisse potenti. Faucibus pulvinar elementum integer enim. Morbi blandit cursus risus at ultrices mi tempus imperdiet nulla. Convallis a cras semper auctor neque vitae tempus. Quam elementum pulvinar etiam non. Rhoncus dolor purus non enim praesent elementum facilisis leo.</p> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
