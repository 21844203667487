import React, { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/esm/Button";
import Logo from "../../../assets/images/logo.svg";
import BlackLogo from "../../../assets/images/black-logo.svg";
import MenuIcon from "../../../assets/images/menu-open-icon.svg";
import "../header/header.sass";
import { HashLink } from 'react-router-hash-link';

const Header = () => {

  const [isActive, setActive] = useState(false);
  const [showA, setShowA] = useState(false);
  const [searchBox, setSearchBox] = useState(false);
  const toggleShowA = () => setShowA(!showA);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [scroll, setScroll] = useState(false);

  const nav = useNavigate();

  useEffect(() => {
  window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);

  return (
    <>
      <header className={scroll ? "scrolled red-header" : "red-header"}>
        <Navbar>
          <Container fluid>
            <Navbar.Brand onClick={()=>{nav("/")}}>
              <img src={Logo} alt="logo" />
            </Navbar.Brand>
            <a className="open-menu-icon" onClick={() => setActive(true)}>
              <img src={MenuIcon} alt="" className="img-fluid" />
            </a>
            <Navbar
              className={
                isActive
                  ? "justify-content-end menu active"
                  : "justify-content-end menu"
              }
            >
              <Nav className="header-navigation">
                <Navbar.Brand onClick={(e)=>{nav("/")}}>
                  <img src={BlackLogo} alt="logo" />
                </Navbar.Brand>
                <a className="mobile-search">
                  <div className="mobile-search-input">
                    <input type="text" placeholder="Search..." />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </a>
                <a className="close-menu-icon" onClick={() => setActive(false)}>
                  <i className="ri-close-line"></i>
                </a>
                <Link
                  to="/about"
                  className={splitLocation[1] === "about" ? "active" : ""}
                  onClick={() => setActive(false)}
                >
                  About
                </Link>
                <HashLink smooth 
                  to="/#moxiestudios" 
                  className={splitLocation[1] === "moxiestudios" ? "active" : ""} 
                  onClick={() => setActive(false)}>Moxie<span>Studios</span>
                </HashLink>
                <HashLink smooth to="/#moxiebuild" 
                  className={splitLocation[1] === "moxiebuild" ? "active" : ""}
                  onClick={() => setActive(false)}>
                  Moxie<span>Build</span>
                </HashLink>
                <Link
                  to="/contact"
                  className={splitLocation[1] === "contact" ? "active" : ""}
                  onClick={() => setActive(false)}
                >
                  Contact
                </Link>
                <Link
                  to="/blog"
                  className={splitLocation[1] === "blog" ? "active" : ""}
                  onClick={() => setActive(false)}
                >
                  Blog
                </Link>
                <a className="sign-in">
                  <Button
                    type="button"
                    className="view-all-btn position-relative btn btn-primary w-auto"
                  >
                    Sign In
                  </Button>
                </a>
                <a className="desk-search">
                  <div className={searchBox ? "input-box open" : "input-box"}>
                    <input type="text" placeholder="Search..." />
                    <span className="search" onClick={() => setSearchBox(true)}>
                      <i className="ri-search-line search-icon"></i>
                    </span>
                    <i
                      className="ri-close-line close-icon"
                      onClick={() => setSearchBox(false)}
                    ></i>
                  </div>
                </a>
              </Nav>
            </Navbar>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
