import React from 'react'
import { Button, Container } from 'react-bootstrap'
import '../footer/footer.sass'
import Logo from '../../../assets/images/footer-logo.svg'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const nav = useNavigate();
  return (
    <footer className='home-footer'>
      <Container fluid>
        <div className="footer-content">
          <div className="footer-left">
            <div className="logo">
              <img src={Logo} alt="" className='img-fluid'/>
            </div>
            <p className='mt-lg-4'>Moxie Solutions Inc | 2023</p>
          </div>
          <div className="footer-right">
            <Button className="default-button" onClick={()=>{nav("/contact")}}>Contact Us</Button>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
